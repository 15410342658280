import React, { useState, useEffect, useRef } from 'react';
import { createBrowserRouter, RouterProvider, useParams } from 'react-router-dom'

import "monday-ui-react-core/tokens";
import { Box, Button, Flex, Menu, MenuItem, MenuTitle, MenuDivider, MenuGridItem, TextField, VibeNext } from "monday-ui-react-core";
import './App.css';
import {MainStaticPage} from './staticPage';
import {PathBenderApp} from './pathbenderapp';

function App() {
  const [user, setUser] = useState(() => ({}));
  const [signupEmail, setSignupEmail] = useState("");
  const [hasUserJustSignedUp, setHasUserJustSignedUp] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPwd, setLoginPwd] = useState("");

  function doSignupUser(e) {
    if (!signupEmail || signupEmail.indexOf("@") == -1 || signupEmail.indexOf(".") == -1) {
      console.log("incorrect email: ", signupEmail);
      return; // do nothing
    }

    fetch("/api/join_waitlist?email=" + signupEmail).then(res => res.json()).then(data => {
      console.log("got data = ", data);
      setHasUserJustSignedUp(true);
    });
  }

  function doLoginUser(e) {
    return;
  }

  function AboutComponent () {
    const params = useParams();
    console.log("p = ", params);
    return (
      <div>about {params.id}</div>
    );
  };

  function AppComponent () {
    return (
      <div>main app</div>
    );
  };

  const router = createBrowserRouter([
    { path: "/", element: <MainStaticPage /> },
    { path: "/login", element: <MainStaticPage location="login" /> },
    { path: "/app", element: <PathBenderApp /> }
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
