// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body {
    font-family: Roberto, sans-serif;
    color: #000D2B;
    background-color: #FFF;
    #text-align: center;
    #padding: 50px;
}

.padded-content {
  #width: 100%;
  padding-left: 140px;
  padding-right:  140px;
}

.container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    #border: 1px solid #333;
    background-color: #000D2B;
}


h1 {
  color: #000D2B;
  font-family: Inter, sans-serif;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px; /* 105.556% */
  letter-spacing: -1.44px;
}

h2 {
  color: #000D2B;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
  letter-spacing: -0.8px;
}

p {
  color: #000D2B;

  /* body */
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;IACI,gCAAgC;IAChC,cAAc;IACd,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,yBAAyB;AAC7B;;;AAGA;EACE,cAAc;EACd,8BAA8B;EAC9B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,8BAA8B;EAC9B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;EAC5B,sBAAsB;AACxB;;AAEA;EACE,cAAc;;EAEd,SAAS;EACT,8BAA8B;EAC9B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;AAClC","sourcesContent":["\nbody {\n    font-family: Roberto, sans-serif;\n    color: #000D2B;\n    background-color: #FFF;\n    #text-align: center;\n    #padding: 50px;\n}\n\n.padded-content {\n  #width: 100%;\n  padding-left: 140px;\n  padding-right:  140px;\n}\n\n.container {\n    max-width: 600px;\n    margin: auto;\n    padding: 20px;\n    #border: 1px solid #333;\n    background-color: #000D2B;\n}\n\n\nh1 {\n  color: #000D2B;\n  font-family: Inter, sans-serif;\n  font-size: 72px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 76px; /* 105.556% */\n  letter-spacing: -1.44px;\n}\n\nh2 {\n  color: #000D2B;\n  font-family: Inter, sans-serif;\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 48px; /* 120% */\n  letter-spacing: -0.8px;\n}\n\np {\n  color: #000D2B;\n\n  /* body */\n  font-family: Inter, sans-serif;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 26px; /* 144.444% */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
