import React, { useState, useEffect, useRef } from 'react';
import { createBrowserRouter, RouterProvider, useParams } from 'react-router-dom'
import "monday-ui-react-core/tokens";
import { Box, Button, Flex, Menu, MenuItem, MenuTitle, MenuDivider, MenuGridItem, TextField, VibeNext } from "monday-ui-react-core";
import './App.css';
import {MyRecommendations} from './myrecs';
import {AboutCompanyBlock} from './staticPage';

function LogoFull() {
	return (
		<Flex direction={Flex.directions.ROW} >
			<img src="/logo_short.jpg" style={{width: '32px', height: '32px'}} onClick={(e) => { window.location.href="/"; }} />
			<p style={{paddingLeft: '24px', fontSize: '20px', fontWeight: '700'}} onClick={(e) => { window.location.href="/"; }}> Pathbender.AI </p>
		</Flex>
	);
}

function NavBar({location, setLocation}) {
	console.log("location = ", location);
	return (
		<div className="padded-content">
			<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN} style={{width: '100%', fontWeight: '700'}}>
				<LogoFull />

				<Flex direction={Flex.directions.ROW} justify={Flex.justify.END}>
					<Button kind={Button.kinds.TERTIARY} style={{fontWeight: '500', textDecoration: location=="data" ? "underline 6px" : "none"}} onClick={(e) => { setLocation("data"); }}> My Data </Button>
					<Button kind={Button.kinds.TERTIARY} style={{fontWeight: '500', textDecoration: location=="quest" ? "underline 6px" : "none"}} onClick={(e) => { setLocation("quest"); }}> Questionnaires </Button>
					<Button kind={Button.kinds.TERTIARY} style={{fontWeight: '500', textDecoration: location=="record" ? "underline 6px" : "none"}} onClick={(e) => { setLocation("record"); }}> Longevity Record </Button>
					<Button kind={Button.kinds.TERTIARY} style={{fontWeight: '500', textDecoration: location=="recommendations" ? "underline 6px" : "none"}} onClick={(e) => { setLocation("recommendations"); }}> Recommendations </Button>
					<Button kind={Button.kinds.TERTIARY} style={{fontWeight: '500'}} onClick={(e) => { window.location.href="/"; }}> Logout </Button>
				</Flex>
			</Flex>
		</div>		
	)
}

function MyData() {
	return (
		<div className="padded-content" style={{paddingTop: '100px', paddingBottom: '100px', backgroundColor: '#FAFAFA'}}>
			<Flex direction={Flex.directions.COLUMN}>
				<div>
					<h1>
					My Linked Data
					</h1>
				</div>

				<div style={{width: '460px' }} >
					<center>
					<p>
						Something about my data
					</p>
					</center>
				</div>
			</Flex>
		</div>
	);
}

function Questionnaires() {
	return (
		<div className="padded-content" style={{paddingTop: '100px', paddingBottom: '100px', backgroundColor: '#FAFAFA'}}>
			<Flex direction={Flex.directions.COLUMN}>
				<div>
					<h1>
					Questionnaires
					</h1>
				</div>

				<div style={{width: '460px' }} >
					<center>
					<p>
						Something about questionnaires.
					</p>
					</center>
				</div>
			</Flex>
		</div>
	);
}

function MyLongevityRecord() {
	return (
	<div>
		<div className="padded-content" style={{paddingTop: '48px', paddingBottom: '48px', backgroundColor: '#FAFAFA'}}>
			<Flex direction={Flex.directions.COLUMN}>
				<Flex direction={Flex.directions.ROW}>
					<div>
						<img src='/human.png' style={{height: '600px'}}/>
					</div>
					<div style={{paddingLeft: '48px'}}>
					<h3> Family History </h3>
						<b> Father: </b> Age/Cause of death 45, MI; colorectal ca, obesity diabetes <br/>
						<b> Mother: </b> Age/Cause of death (alive); Alzheimers dementia, Hypertension, Breast Cancer <br/>

					<h3> Active Problems </h3>
						- High blood pressure <br/>
						- Poor sleep duration/quality <br/>
						- Elevated liver enzymes (ALT) <br/>
						- Mildly elevated fasting glucose <br/>

					<h3> Last physical/lab panel: 3/12/22 </h3>

					<h3> Current Medications: None</h3>

					<h3> Supplements: </h3> Multivitamin

					<h3> Diet: </h3> Nonvegeterian, high saturated fat

					<h3> Exercise: </h3> light walking daily (zone 1 - 20 min), no resistance training; Avg Resting HR: 75 BPM

					<h3> Stress: </h3> Moderate, HR variability: 

					</div>
				</Flex>
			</Flex>
		</div>

		<div className="padded-content" style={{backgroundColor: '#EDF6FF', paddingTop: '18px', paddingBottom: '18px'}}>
						<p>
						You are a 43 year old man with high blood pressure, ranging from 125-145 systolic over the past two months.
						Your mildly elevated fasting blood suger, elevated liver enzymes, increase in weight poor sleep quality, 
						and low activity level could reflect insuling resistance.
						You have an elevated risk of heart disease and colorectal cancer which were present in your father, 
						as well as the risk of developing dementia later in your life, like your mother.
						</p>
		</div>

		<Flex direction={Flex.directions.ROW} className="padded-content" style={{backgroundColor: '#FAFAFA', paddingTop: '18px', paddingBottom: '18px'}}>
			<div style={{maxWidth: '50%'}}>
				<h3> Top 5 Things To Do </h3>
				<p> 
				1. Increase physical activity through HIIT, Zone 2 cardio, and resistance training. 
				Click here for baslines VO2 max testing.
				</p>

				<p>
				2. Reduce dietary saturated fat from animal sources. Eat at least one salad per day with beans and nuts for protein.
				Click here to schedule a nutritional consultation.
				</p>

				<p>
				3. Increase sleep duration. Click here to schedule an appointment at the Sleep Center.
				</p>

				<p>
				4. Schedule your annual lab panel.
				</p>

				<p>
				5. Schedule a colonoscopy or consider an at-home screening test.
				</p>
			</div>

			<Flex direction={Flex.directions.COLUMN}>
				<div style={{paddingLeft: '48px'}}>
					<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}>
						<p><b>Cardioviscular</b> </p>
						<div style={{width: '200px', height: '24px', background: '#DDD', marginLeft: '48px',
							background: 'linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)'}}> 
						</div>
					</Flex>

					<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}>
						<p><b>Metabolic</b> </p>
						<div style={{width: '200px', height: '24px', background: '#DDD', marginLeft: '48px',
							background: 'linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)'}}> 
						</div>
					</Flex>

					<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}>
						<p><b>Brain</b> </p>
						<div style={{width: '200px', height: '24px', background: '#DDD', marginLeft: '48px',
							background: 'linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)'}}> 
						</div>
					</Flex>

					<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}>
						<p><b>Cancer</b> </p>
						<div style={{width: '200px', height: '24px', background: '#DDD', marginLeft: '48px',
							background: 'linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)'}}> 
						</div>
					</Flex>

					<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}>
						<p><b>Bone/Muscle</b> </p>
						<div style={{width: '200px', height: '24px', background: '#DDD', marginLeft: '48px',
							background: 'linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)'}}> 
						</div>
					</Flex>

					<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}>
						<p><b>Mental/Social</b> </p>
						<div style={{width: '200px', height: '24px', background: '#DDD', marginLeft: '48px',
							background: 'linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c)'}}> 
						</div>
					</Flex>
				</div>
				<div>
					<center>
						<h2> Overall score: 67 </h2>
					</center>
				</div>
			</Flex>
		</Flex>
	</div>
	);
}



function PathBenderApp() {
	const [location, setLocation] = useState("record");

	return (
		<div>
			<NavBar location={location} setLocation={setLocation} />
			{ location == "data" ? <MyData /> : null }
			{ location == "quest" ? <Questionnaires /> : null }
			{ location == "record" ? <MyLongevityRecord /> : null }
			{ location == "recommendations" ? <MyRecommendations /> : null }

			<hr style={{margin: '0'}}/>
			<AboutCompanyBlock />
		</div>
	);
}

export {PathBenderApp};