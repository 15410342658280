import React, { useState, useEffect, useRef } from 'react';
import { createBrowserRouter, RouterProvider, useParams } from 'react-router-dom'
import "monday-ui-react-core/tokens";
import { Box, Button, Flex, Menu, MenuItem, MenuTitle, MenuDivider, MenuGridItem, TextField, VibeNext } from "monday-ui-react-core";
import './App.css';


function GetHeaderAndBodyForTest(name) {
	let recHeader = "";
	let recBody = "";
	let recTopCategory = "Medical Recommendations"
	let recBottomCategory = "";

	if (name == "blood_panel") {
		recHeader = "Blood panel";
		recBody = "Full panel blood test. More details to follow.";
		recBottomCategory = "Crucial";
	} else if (name == "other1") {
		recHeader = "Another Diagnostic"
		recBody = "More information for other diagnostics coming up soon."
		recBottomCategory = "Additional";
	}

	return {recHeader, recBody, recTopCategory, recBottomCategory};
}


function RecommendationBox({name, setSpecificRec}) {
	let {recHeader, recBody} = GetHeaderAndBodyForTest(name);

	//style={{position: 'relative', right: '0'}}>
	return (
		<div style={{width: '100%', border: '1px solid #939393', marginTop: '28px', marginBottom: '28px', paddingLeft: '32px', paddingRight: '32px'}}>
			<Flex direction={Flex.directions.ROW} justify={Flex.justify.START} style={{width: '100%'}} >
				<div style={{width: '107px', height: '107px', backgroundColor: '#D9D9D9', marginTop: '32px', marginBottom: '32px'}}>
					
				</div>

				<div style={{marginLeft: '96px', width: '60%'}}>
					<h3> {recHeader} </h3>

					<p> {recBody} </p>
				</div>

				<div> 
					<Button kind={Button.kinds.SECONDARY} onClick={(e) => { setSpecificRec(name); } }> Show details &#8594; </Button> 
				</div>
			</Flex>
		</div>
	);
}

function ClinicRecommendationBox({name}) {
	let recHeader = "", recPrice = "free";
	let recDetails = "Please provide comprehensive answers to those questions."

	if (name == "test1") {
		recHeader = "St Mary's hospital";
		recPrice = "free"
	} else if (name == "test2") {
		recHeader = "St John's hospital";
		recPrice = "$99.99"
	} else {

	}

	//style={{position: 'relative', right: '0'}}>
	return (
		<div style={{width: '100%', border: '1px solid #939393', marginTop: '28px', marginBottom: '28px', paddingLeft: '32px', paddingRight: '32px'}}>
			<Flex direction={Flex.directions.ROW} justify={Flex.justify.START} style={{width: '100%'}} >
				<div style={{width: '107px', height: '107px', backgroundColor: '#D9D9D9', marginTop: '32px', marginBottom: '32px'}}>
					
				</div>

				<div style={{marginLeft: '96px', width: '60%'}}>
					<h3> {recHeader} </h3>
					<p> {recPrice} </p>

					<p> {recDetails} </p>
				</div>

				<div> 
					<Button kind={Button.kinds.SECONDARY} onClick={(e) => { } }> Book &#8594; </Button> 
				</div>
			</Flex>
		</div>
	);
}


function SpecificRec({testType, setSpecificRec}) {
	let {recHeader, recBody, recTopCategory, recBottomCategory} = GetHeaderAndBodyForTest(testType);	
	return (
		<div>
			<div className="padded-content" style={{paddingTop: '12px', paddingBottom: '48px', backgroundColor: '#FAFAFA'}}>
				<Flex direction={Flex.directions.COLUMN} style={{margin: '0'}}>
					<div style={{width: '100%'}}>
						<p style={{marginBottom: '10px'}}> <a onClick={ () => { setSpecificRec(""); } }><u>Medical Recommendations</u> > <u>Crucial </u></a></p>
					</div>

					<div style={{width: '100%' }}>
						<h1 style={{marginTop: '0'}}>{recHeader}</h1>
					</div>
					<Flex direction={Flex.directions.ROW} style={{width: '100%', margin: '0'}} >
						<Button kind={Button.kinds.SECONDARY}> Add data or symptoms </Button>
						<Button kind={Button.kinds.SECONDARY} style={{marginLeft: '16px'}}> Link wearables </Button>
						<Button kind={Button.kinds.SECONDARY} style={{marginLeft: '16px'}}> Ask a question &#8594; </Button>
					</Flex>
				</Flex>
			</div>

			<div className="padded-content" style={{paddingTop: '24px', paddingBottom: '48px', overflow: 'hidden'}}>

				<div style={{paddingRight: '24px', borderRight: 'solid 1px', align: 'top', top: '0', verticalAlign: 'top', float: 'left', width: '80px'}}>
					<h3> Why </h3> <br/>
					<h3> Where </h3> <br/>
					<h3> More </h3> <br/>

				</div>

				<div style={{marginLeft: '148px', paddingLeft: '24px'}}>
					<h2> Why </h2>
					<p> 
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
					</p>
					<h2> Where </h2>
					<p> Recommended for you: </p>

					<ClinicRecommendationBox name="test1" />
					<ClinicRecommendationBox name="test2" />
					<ClinicRecommendationBox name="test1" />

					<h2> More Information </h2>
					<p> Coming soon </p>
				</div>
			</div>
		</div>
	);
}

function MyRecommendations() {
	const [ specificRec, setSpecificRec ] = useState("")

	if (specificRec == "blood_panel" || specificRec == "other1") {
		return (<SpecificRec testType={specificRec} setSpecificRec={setSpecificRec} />);
	}

	return (
		<div className="padded-content" style={{paddingTop: '12px', paddingBottom: '48px', backgroundColor: '#FAFAFA'}}>
			<Flex direction={Flex.directions.COLUMN} style={{margin: '0'}}>
				<div style={{width: '100%' }}>
					<h1>My Recommendations</h1>
				</div>
				<Flex direction={Flex.directions.ROW} style={{width: '100%', margin: '0'}} >
					<Button kind={Button.kinds.SECONDARY}> Add data or symptoms </Button>
					<Button kind={Button.kinds.SECONDARY} style={{marginLeft: '16px'}}> Link wearables </Button>
					<Button kind={Button.kinds.SECONDARY} style={{marginLeft: '16px'}}> Ask a question &#8594; </Button>
				</Flex>

				<div style={{paddingLeft: '0', width: '100%'}}>
					<p style={{paddingLeft: '0'}}>
						Disclaimer text text text text text text text text text text text text text text text <br/> 
						text text text text text text text text text
					</p>
				</div>

				<Flex direction={Flex.directions.ROW} style={{width: '100%', margin: '0', paddingBottom: '48px'}} >
					<Button kind={Button.kinds.SECONDARY}> Medical </Button>
					<Button kind={Button.kinds.SECONDARY} style={{marginLeft: '16px'}}> Lifestyle </Button>
				</Flex>

				<div className="padded-content" style={{width: '100%', paddingTop: '48px', paddingBottom: '48px', backgroundColor: '#2E2E2E'}}>
					<div>
							<center>
								<h2 style={{color: '#FFF'}}> You can do better! </h2>
							</center>

							<center>
							<div>
								<p style={{color: '#FFF'}}> 
								We have reviewed you insurance plan and we <br/>
								have found that you could save up to $### <br/>
								if you adjust your plan <br/>
								</p>
							</div>
							</center>

							<center>
								<Button kind={Button.kinds.SECONDARY} style={{color: '#FFF'}}> Find out more &#8594; </Button>
							</center>
					</div>
				</div>
			</Flex>

			<div>
			<h2> Crucial </h2>

			<RecommendationBox name="blood_panel" setSpecificRec={setSpecificRec} />
			<RecommendationBox name="other1" setSpecificRec={setSpecificRec} />

			<h2> Additional </h2>

			<RecommendationBox name="other1" setSpecificRec={setSpecificRec}/>
			<RecommendationBox name="other1" setSpecificRec={setSpecificRec}/>

			<h2> Extra </h2>

			<RecommendationBox name="other1" setSpecificRec={setSpecificRec}/>
			<RecommendationBox name="other1" setSpecificRec={setSpecificRec}/>

			</div>
		</div>
	);
}

export {MyRecommendations};