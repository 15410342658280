import React, { useState, useEffect, useRef } from 'react';
import { createBrowserRouter, RouterProvider, useParams } from 'react-router-dom'
import "monday-ui-react-core/tokens";
import { Box, Button, Flex, Menu, MenuItem, MenuTitle, MenuDivider, MenuGridItem, TextField, VibeNext } from "monday-ui-react-core";
import './App.css';


function LogoFull() {
	return (
		<Flex direction={Flex.directions.ROW} >
			<img src="/logo_short.jpg" style={{width: '32px', height: '32px'}} onClick={(e) => { window.location.href="/"; }} />
			<p style={{paddingLeft: '24px', fontSize: '20px', fontWeight: '700'}} onClick={(e) => { window.location.href="/"; }}> Pathbender.AI </p>
		</Flex>
	);
}


function NavBar() {
	return (
		<div className="padded-content">
			<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN} style={{width: '100%', fontWeight: '700'}}>
				<LogoFull />

				<Flex direction={Flex.directions.ROW} justify={Flex.justify.END}>
					<Button kind={Button.kinds.TERTIARY} style={{fontWeight: '500'}} onClick={(e) => { window.location.href="/"; }}> For Patients </Button>
					<Button kind={Button.kinds.TERTIARY} style={{fontWeight: '500'}} onClick={(e) => { window.location.href="/"; }}> For Healthcare providers </Button>
					<Button kind={Button.kinds.TERTIARY} style={{fontWeight: '500'}} onClick={(e) => { window.location.href="/"; }}> For Insurers </Button>
					<Button kind={Button.kinds.TERTIARY} style={{fontWeight: '500'}} onClick={(e) => { window.location.href="/login"; }}> Login </Button>
				</Flex>
			</Flex>
		</div>
	);
}

function MainBanner() {
	return (
		<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN} style={{backgroundColor: '#EDF6FF'}}>
			<div style={{paddingLeft: '140px', paddingRight: '100px'}}>
				<h1> 
				Live longer <br/>
				Live healthier
				</h1>

				<p>
				Be the first to experience the future of longevity with PathBender AI. 
				Sign up today to get early access and exclusive updates.
				</p>
				<br/><br/>

				<Flex direction={Flex.directions.ROW} >
					<Button kind={Button.kinds.SECONDARY}> Log in </Button>
					<Button kind={Button.kinds.SECONDARY} style={{marginLeft: '16px'}}> Sign up &#8594; </Button>
				</Flex>
			</div>
			<img src="/happy_face.png" />
		</Flex>
	);
}

function MediumBlock() {
	return (
		<div className="padded-content" style={{paddingTop: '100px', paddingBottom: '100px'}}>
			<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}>
				<div>
					<h2>
					Medium length title for <br/> a building block
					</h2>
				</div>

				<div>
					<h2>20%</h2>
					Some content
				</div>


				<div>
					<h2>20%</h2>
					Some other content
				</div>
			</Flex>
		</div>
	);
}


function AboutPBBlock() {
	return (
		<div className="padded-content" style={{paddingTop: '100px', paddingBottom: '100px', backgroundColor: '#FAFAFA'}}>
			<Flex direction={Flex.directions.COLUMN}>
				<div>
					<h2>
					About Pathbender.AI
					</h2>
				</div>

				<div style={{width: '460px' }} >
					<center>
					<p>
					Be the first to experience the future of longevity with PathBender AI. Sign up today to get early access and exclusive updates.
					</p>
					</center>
				</div>


				<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN}>
					<div style={{backgroundColor: '#FFFFFF', padding: '24px', margin: '24px',
								border: '1px solid #C3C2C1' }} > 
						<img src='/icon1_chart.png' /> 
						<br/>

						<b> Share your medical records </b>
						<p>
						Short text describing a feature of your product/service. Think about how it can benefit your potential customer. The text should convey the objectives of your product/service. Try being expressive yet concise.
						</p>
					</div>

					<div style={{backgroundColor: '#FFF', padding: '24px', margin: '24px',
								border: '1px solid #C3C2C1'}} > 
						<img src='/icon2_security.svg' /> 
						<br/>

						<b> Share any additional information </b>
						<p>
						Short text describing a feature of your product/service. Think about how it can benefit your potential customer. The text should convey the objectives of your product/service. Try being expressive yet concise.
						</p>
					</div>


					<div style={{backgroundColor: '#FFF', padding: '24px', margin: '24px',
								border: '1px solid #C3C2C1'}} > 
						<img src='/icon3_team.svg' /> 
						<br/>

						<b> See your recommendations </b>
						<p>
						Short text describing a feature of your product/service. Think about how it can benefit your potential customer. The text should convey the objectives of your product/service. Try being expressive yet concise.
						</p>
					</div>
				</Flex>
			</Flex>
		</div>
	);
}


function WaitListBlock() {
	return (
		<div className="padded-content" style={{paddingTop: '100px', paddingBottom: '100px', backgroundColor: '#2E2E2E'}}>
			<center>
				<h2 style={{color: '#FFF'}}> Waitlist </h2>

				<p style={{color: '#FFF'}}> Subscribe to our updates </p>

				<div style={{width: '400px'}}>
					<Flex direction={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN} gap={Flex.gaps.LARGE}>
						<TextField placeholder="Your Email" style={{maxWidth: '200px' }} />
						<Button kind={Button.kinds.SECONDARY} style={{color: '#FFF'}}> Subscribe &#8594; </Button>
					</Flex>
				</div>

			</center>
		</div>
	);
}

function AboutCompanyBlock() {
	return (
		<div className="padded-content" style={{backgroundColor: '#FAFAFA', paddingTop: '48px', paddingBottom: '48px'}}>
			<Flex direction={Flex.directions.ROW} gap={Flex.gaps.LARGE}>
				<div>
					<b> COMPANY </b> <br/> <br/>
					<Button kind={Button.kinds.TERTIARY} > About </Button> <br/>
					<Button kind={Button.kinds.TERTIARY} > Our offering </Button> <br/>
					<Button kind={Button.kinds.TERTIARY} > Blog </Button> <br/>
					<Button kind={Button.kinds.TERTIARY} > Careers </Button> <br/>
				</div>

				<div style={{paddingLeft: '160px'}}>
					<b> RESOURCES </b> <br/><br/>
					<Button kind={Button.kinds.TERTIARY} > Request demo </Button> <br/>
					<Button kind={Button.kinds.TERTIARY} > Tips & Guides </Button> <br/>
					<Button kind={Button.kinds.TERTIARY} > Integrations </Button> <br/>
					<Button kind={Button.kinds.TERTIARY} > Documentation </Button> <br/>
				</div>

				<div style={{paddingLeft: '160px'}}>
					<b> HELP </b> <br/><br/> 
					<Button kind={Button.kinds.TERTIARY} > FAQ </Button> <br/>
					<Button kind={Button.kinds.TERTIARY} > Contact Support </Button> <br/>
					<Button kind={Button.kinds.TERTIARY} > Account </Button> <br/>
					<br/><br/>
				</div>
			</Flex>
		</div>
	)
}

function MainBannerLogin() {
	return (
		<Flex direction={Flex.directions.COLUMN} justify={Flex.justify.CENTER} style={{backgroundColor: '#EDF6FF', height: '600px'}} gap={Flex.gaps.LARGE} >
				<Flex diection={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN} gap={Flex.gaps.LARGE} style={{width: '400px'}}> 
					<p>Email:</p> 
					<div style={{width: '250px'}}><TextField placeholder="Your Email" /> </div>
				</Flex>

				<Flex diection={Flex.directions.ROW} justify={Flex.justify.SPACE_BETWEEN} gap={Flex.gaps.LARGE} style={{width: '400px'}}> 
					<p> Password:</p> 
					<div style={{width: '250px'}}><TextField placeholder="Your Password" type={TextField.types.PASSWORD}/> </div>
				</Flex>
				<Button kind={Button.kinds.SECONDARY} style={{color: '#FFF'}} onClick={(e) => { window.location.href="/app"; }}> <p>Login &#8594; </p> </Button>
		</Flex>
	);
}

function MainStaticPage({location}) {
	console.log(location);

	if (!location) {
		return (
			<div>
				<NavBar />
				<MainBanner />
				<MediumBlock />
				<AboutPBBlock />
				<WaitListBlock />
				<AboutCompanyBlock />
			</div>
		);
	} else if (location == "login") {
		return (
			<div>
				<NavBar />
				<MainBannerLogin />
			</div>
		);
	}
}

export {MainStaticPage, AboutCompanyBlock};